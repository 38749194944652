@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@100&display=swap");

.container-section {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.titre-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 500%;
  font-family: "Heebo", sans-serif;
  padding: 1.2%;
  color: antiquewhite;
  background-color: #0003;
}

.sous-titre-section {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(4vw + 4vh - 0.5vmin);
  font-family: "Heebo", sans-serif;
  padding-top: 8%;
  padding-bottom: 1%;
}

.description-clinique {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 130%;
  width: 70%;
  padding: 2%;
  font-family: "Heebo", sans-serif;
  font-weight: bold;
  color: black;
  line-height: 130%;
}

.description-clinique-courte {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  padding: 2%;
  font-family: "Heebo", sans-serif;
  font-size: 120%;
  line-height: 2rem;
}
