.container-liens {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  flex-shrink: inherit;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.container-liens-mobile {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  flex-shrink: inherit;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.liens-menu {
  padding: 5%;
  font-family: "Heebo", sans-serif;
  font-size: 120%;
  color: black;
}
.liens-menu-mobile {
  padding: 5%;
  margin: 3%;
  align-items: center;
  justify-content: center;
  font-family: "Heebo", sans-serif;
  font-size: 120%;
  color: black;
}
