@import url(https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.14.0/css/font-awesome.css);
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);
@import url(https://fonts.googleapis.com/css2?family=Heebo:100,500,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Heebo:wght@100&display=swap);
html {
  scroll-behavior: smooth;
  height: 100%;
}

u {
  text-decoration: underline;
}

g {
  font-weight: 700;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Heebo",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-self: stretch;
  flex-wrap: wrap;
  height: 100%;
  width: auto;
}

.container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-align: center;
  justify-content: center;
  flex-wrap: wrap;
  height: 100%;
  min-height: 30px;
  display: block;
  width: 100%;
  position: relative;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

/* .App-link {
  color: #61dafb;
} */

.leaflet-container {
  width: 100%;
  height: 300px;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 30px;
  height: 24px;
  right: 36px;
  top: 37%;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #faebd7;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #faebd7;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #faebd7;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  z-index: 2000 !important;
}

/* General sidebar styles */
.bm-menu {
  z-index: 2000 !important;
  height: 100vh;
  background: #000;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  display: flex;
  flex-direction: column;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  display: flex;
  flex-direction: column;
  line-height: 250%;
}

/* Individual item */
.bm-item {
  margin: 10%;
  color: white;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  z-index: 2000 !important;
}

.Presentation {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.Presentation-header {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.titre-presentation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Heebo";
  margin: 6%;
}

#citation {
  font-style: italic;
  font-weight: 800;
  font-size: 120%;
  font-family: "Heebo";
}

#profession {
  font-size: 130%;
}

.Presentation-body {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-items: center;
  align-items: center;
}
#texte_et_portrait {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  margin: 2%;
}

.texte-presentation {
  display: flex;
  flex: 1 1;
  margin: 3%;
  line-height: 2.5rem;
  font-size: 140%;
  font-family: "Heebo";
  justify-content: center;
  text-align: center;
  flex-direction: column;
}

.Presentation-ope {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1;
  height: 100%;
  width: 100%;
  min-width: 0;
  margin: 2%;

  /* pointer-events: none; */
}

.Presentation-portrait {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  flex: 0.3 1;
  height: 100%;
  width: 100%;
  min-width: 0;
  margin: 4%;

  /* pointer-events: none; */
}

.description {
  height: 200px;
  width: 200px;
  font-size: 25px;
  position: absolute;
  left: 40%;
  margin-left: -100px;
  top: 40%;
  padding: 10%;
  margin-top: -100px;
  color: antiquewhite;
  background-color: black;
}

.links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  padding: 2%;
  font-family: "Heebo";
}

.link {
  color: black;
  font-size: 120%;
  height: 100%;
  text-decoration: none;
  border-bottom-width: 20px;
  border-bottom-color: black;
  padding: 2%;
  box-shadow: 10px 5px 5px #0001;
}

/* @media (prefers-reduced-motion: no-preference) {
  .Presentation-photo {
    animation: Presentation-logo-spin infinite 20s linear;
  }
} */

.Presentation-link {
  color: #61dafb;
}

.Presentation-texte {
  color: #61dafb;
}

/* @keyframes Presentation-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.container-liens {
  display: flex;
  flex: 1 1;
  flex-wrap: wrap;
  flex-shrink: inherit;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.container-liens-mobile {
  display: flex;
  flex: 1 1;
  flex-wrap: wrap;
  flex-shrink: inherit;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.liens-menu {
  padding: 5%;
  font-family: "Heebo", sans-serif;
  font-size: 120%;
  color: black;
}
.liens-menu-mobile {
  padding: 5%;
  margin: 3%;
  align-items: center;
  justify-content: center;
  font-family: "Heebo", sans-serif;
  font-size: 120%;
  color: black;
}

.container-section {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.titre-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 500%;
  font-family: "Heebo", sans-serif;
  padding: 1.2%;
  color: antiquewhite;
  background-color: #0003;
}

.sous-titre-section {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(4vw + 4vh - 0.5vmin);
  font-family: "Heebo", sans-serif;
  padding-top: 8%;
  padding-bottom: 1%;
}

.description-clinique {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 130%;
  width: 70%;
  padding: 2%;
  font-family: "Heebo", sans-serif;
  font-weight: bold;
  color: black;
  line-height: 130%;
}

.description-clinique-courte {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  padding: 2%;
  font-family: "Heebo", sans-serif;
  font-size: 120%;
  line-height: 2rem;
}

