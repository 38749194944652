.Presentation {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.Presentation-header {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.titre-presentation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Heebo";
  margin: 6%;
}

#citation {
  font-style: italic;
  font-weight: 800;
  font-size: 120%;
  font-family: "Heebo";
}

#profession {
  font-size: 130%;
}

.Presentation-body {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-items: center;
  align-items: center;
}
#texte_et_portrait {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  margin: 2%;
}

.texte-presentation {
  display: flex;
  flex: 1;
  margin: 3%;
  line-height: 2.5rem;
  font-size: 140%;
  font-family: "Heebo";
  justify-content: center;
  text-align: center;
  flex-direction: column;
}

.Presentation-ope {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  height: 100%;
  width: 100%;
  min-width: 0;
  margin: 2%;

  /* pointer-events: none; */
}

.Presentation-portrait {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  flex: 0.3;
  height: 100%;
  width: 100%;
  min-width: 0;
  margin: 4%;

  /* pointer-events: none; */
}

.description {
  height: 200px;
  width: 200px;
  font-size: 25px;
  position: absolute;
  left: 40%;
  margin-left: -100px;
  top: 40%;
  padding: 10%;
  margin-top: -100px;
  color: antiquewhite;
  background-color: black;
}

.links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  padding: 2%;
  font-family: "Heebo";
}

.link {
  color: black;
  font-size: 120%;
  height: 100%;
  text-decoration: none;
  border-bottom-width: 20px;
  border-bottom-color: black;
  padding: 2%;
  box-shadow: 10px 5px 5px #0001;
}

/* @media (prefers-reduced-motion: no-preference) {
  .Presentation-photo {
    animation: Presentation-logo-spin infinite 20s linear;
  }
} */

.Presentation-link {
  color: #61dafb;
}

.Presentation-texte {
  color: #61dafb;
}

/* @keyframes Presentation-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
